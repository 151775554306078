.header {
    padding: 0;
    height: auto;
    overflow: visible;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    width: 100%;
    z-index: 99;
} 