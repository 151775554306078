/* You can add global styles to this file, and also import other style files */
@import "src/sass/variables";
@import "src/sass/bootstrap/bootstrap.scss";
@import "src/sass/custom"; 


:root {
    font-size: 13pt;
}

p {
    font-family: $font-text;
    font-size: 0.8rem;
    font-weight: 500;
}

.font {
    &__primary {
        font-family: $font-primary;
    }

    &__secondary {
        font-family: $font-secondary;
    }
}

.white {
    color: $white;
}

.site {
    .btn-rounded {
        background-color: $brand-primary;
        border-radius: 50px;
        padding: 10px 35px;
        color: white;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover {
            color: white;
            background-color: darken($brand-primary, 5%);
        }
    }
    .btn-black {
        background-color: black;
        color: white;

        &:hover {
            background-color: lighten($color: #000000, $amount: 25%);
        }
    }

    .sub-title {
        color: black;
        font-family: $font-secondary;
    
        @media (min-width: 1200px) {
            font-size: 2em;
        }

        &--white {
            color: white;
        }
    }

    .title {
        font-family: $font-primary;
        color: $brand-primary;
        text-transform: uppercase;
        margin-bottom: 25px;

        &--white {
            color: white;
        }
    }

    .error {
        color: red;
    }
}